<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-09-01 21:26:05
 * @Description: 会议房间列表
-->
<template>
  <div class="MeetingGroupList">
    <!-- 参会记录 -->
    <ul class="infinite-list" v-if="optType == 'groupWill'" v-infinite-scroll="getJoinRecords"
      :infinite-scroll-disabled="loading || noMore">
      <li v-if="recordList.length > 0" v-for="(item, index) in recordList" :key="index" class="infinite-list-item">
        <div class="avatar">
          <img :src="require('@/assets/icons/icon_group.png')" alt="" />
        </div>
        <div class="meeting_cell">
          <div class="meeting_name">{{ item.meetingSubject && JSON.parse(item.meetingSubject).length > 1 ?
              `${JSON.parse(item.meetingSubject)[0]} 等${JSON.parse(item.meetingSubject).length}人` :
              JSON.parse(item.meetingSubject)[0] || '未知联系人'
          }}</div>
          <div class="meeting_num">组会号：{{ item.meetingNumber && JSON.parse(item.meetingNumber).length > 1 ?
              `${JSON.parse(item.meetingNumber)[0]} ...` : JSON.parse(item.meetingNumber)[0] || '暂无'
          }}</div>
        </div>
        <div class="operation" @click="onJoinRecord(item)">
          <div class="operation_text">组会</div>
          <div class="operation_icon"></div>
        </div>
      </li>
      <div v-else class="no-data">
        <p>暂无参会记录</p>
      </div>
    </ul>
    <!-- 快捷会议 -->
    <ul class="infinite-list" v-if="optType == 'join'">
      <li v-if="roomList.length > 0" v-for="(item, index) in roomList" :key="index" class="infinite-list-item">
        <div class="avatar">
          <img :src="require('@/assets/icons/icon_group.png')" alt="" />
        </div>
        <div class="meeting_cell">
          <div class="meeting_name">{{ item.name }}</div>
          <div class="meeting_num">会议号：{{ item.vmrId }}</div>
        </div>
        <div class="operation" @click="onJoinRoom(item)">
          <div class="operation_text">组会</div>
          <div class="operation_icon"></div>
        </div>
      </li>
      <div v-else class="no-data">
        <p>暂无快捷会议</p>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MeetingGroupList",
  data() {
    return {
      page: 1,
      loading: false,
      noMore: false,
      recordList: [],
      roomList: [],
    };
  },
  computed: {},
  components: {},
  props: {
    optType: {
      type: String,
      default: "groupWill",
    },
  },
  methods: {
    // 查询会议室列表
    getShortcutRooms() {
      this.$api.getShortcutRooms().then((res) => {
        const { data } = res;
        if (data) {
          this.roomList = data;
        }
      });
    },
    // 查询参会记录
    async getJoinRecords(onRefresh) {
      if (onRefresh) {
        this.page = 1;
        this.recordList = []
      }
      this.loading = true
      const res = await this.$api.getJoinRecords({
        page: this.page,
        pageSize: 10,
        recordType: 2
      });
      const { code, data } = res;
      if (code == 'B200') {
        this.loading = false;
        this.noMore = data.records.length == 0;
        this.page = data.records.length > 0 ? this.page + 1 : this.page;
        this.recordList = [...this.recordList, ...data.records];
      }
    },
    // 发起参会记录
    async onJoinRecord(item) {
      const { meetingSubject, meetingNumber } = item;
      const meetingSubjectJson = JSON.parse(meetingSubject)
      const meetingNumberJson = JSON.parse(meetingNumber);
      const callArr = []
      for (let i = 0; i < meetingSubjectJson.length; i++) {
        callArr.push(`${meetingSubjectJson[i]},${meetingNumberJson[i]}`)
      }
      this.$utils.showLoading({ text: '正在发起会议，请稍后...' });
      await this.$api.addJoinRecord({
        name: '会议记录',
        recordType: 2,
        meetingSubject,
        meetingNumber
      })
      await this.getJoinRecords(true)
      this.$JX.vStartConference(callArr.join(';'), 1);
      this.$utils.hideLoading()
    },
    // 发起快捷会议
    onJoinRoom(item) {
      if (item.id == "66666") {
        this.$router.push({
          name: "inMeeting",
          query: {
            conferenceNumber: item.vmrId,
            conferencePassword: "",
            conferenceRoom: true,
          },
        });
        return;
      }
      const { showLoading, hideLoading } = this.$utils;
      showLoading();
      this.$api
        .getRoomDetail(item.id)
        .then((res) => {
          const { vmrId, password } = res.data;
          this.$router.push({
            name: "inMeeting",
            query: {
              conferenceNumber: vmrId,
              conferencePassword: password,
              conferenceRoom: true,
            },
          });
        })
        .finally(() => {
          hideLoading();
        });
    },
  },
  created() {
    const optType = this.optType;
    if (optType === "join") {
      // this.getShortcutRooms();
    }
    if (optType === "groupWill") {
      this.getJoinRecords(true);
    }
  },
  beforeUnmount() { },
};
</script>

<style lang="scss" scoped>
.MeetingGroupList {
  overflow: auto;

  .infinite-list {
    height: 815px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 16px;
    overflow-y: scroll;

    .infinite-list-item {
      display: flex;
      align-items: center;
      color: #fff;
      padding: 20px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .avatar {
        width: 52px;
        height: 52px;
        background: #30354d;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 100%;

        img {
          width: 100%;
        }
      }

      .meeting_cell {
        width: 245px;
        line-height: 24px;

        .meeting_name {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.9);
          white-space: nowrap;
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .meeting_num {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.5);
          white-space: nowrap;
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .operation {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        font-size: 16px;

        &:active {
          opacity: 0.5;
        }

        .operation_text {
          color: rgba(255, 255, 255, 0.8);
          margin-right: 8px;
          white-space: nowrap;
        }

        .operation_icon {
          width: 24px;
          height: 24px;
          background: url(../../assets/icons/icon_phone.png) no-repeat;
          background-size: 100%;
        }
      }
    }

    .no-data {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff
    }
  }
}
</style>
