<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-08-24 16:26:30
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/AddressBookList.vue
 * @Description: 通讯录
-->
<template>
  <div :class="{ AddressBookList: true, meeting: meeting }" v-loading="loading" element-loading-text="加载中...">
    <div class="address_book_search">
      <el-input v-model="filterText" class="search-input" placeholder="请输入关键字">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search />
          </el-icon>
        </template>
      </el-input>
    </div>
    <el-tree default-expand-all show-checkbox :props="{ children: 'childNodeList', disabled: disabledNode }"
      :data="data" ref="addressBook" node-key="id" @check="handleCheck" :filter-node-method="filterNode">
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span v-if="data.type == 'ORG'">{{ data.name }}</span>
          <span v-if="data.type == 'STAFF'">{{ data.name }}（{{ data.numberInfo.length > 0 ?
              data.numberInfo[0].userNumber : data.account
          }}）</span>
        </span>
      </template>
    </el-tree>
  </div>
</template>

<script>
import { Search } from "@element-plus/icons";
import { async } from "q";
export default {
  name: "AddressBookList",
  components: {
    Search,
  },
  data() {
    return {
      data: [],
      loading: false,
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.addressBook.filter(val);
    },
  },
  computed: {},
  props: {
    meeting: {
      default: false,
    },
  },
  methods: {
    getContacts() {
      this.loading = true
      this.$api.getTreeContacts().then((res) => {
        const { data, code } = res;
        if (code == 'B200') {
          this.data = data.jiaXunContacts
        }
        this.loading = false
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    disabledNode(data, node) {
      return data.type == "ORG" && data.childNodeList == 0
    },
    handleCheck() {
      const data = [];
      const nodes = this.$refs.addressBook.getCheckedNodes();
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].type == "STAFF" && data.push(nodes[i]);
      }
      this.$emit("checkedUser", data);
      this.$emit("checkedNodes", data);
    },
    removeCheck(arr) {
      this.$refs.addressBook.setCheckedNodes(arr);
    },
    clearCheck() {
      this.$refs.addressBook.setCheckedNodes([]);
    }
  },
  mounted() {
    this.getContacts()
  },
  created() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.AddressBookList {
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 830px;

  .search-input {
    background: #4c556a;
    border-radius: 4px;
    margin-bottom: 5px;

    .el-input__icon {
      line-height: 44px;
    }
  }

  .el-tree {
    background-color: transparent !important;

    .el-tree-node__content {
      height: 34px;
      background-color: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      .el-tree-node__label {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
      }

      .el-checkbox__input .el-checkbox__inner {
        background: transparent;
        border-color: rgba(255, 255, 255, 0.6);
      }

      .el-tree-node:focus {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.meeting {
  height: 90vh;
}

.custom-tree-node {
  font-size: 16px;
}
</style>
